* { box-sizing: border-box; }

body {
    position: relative;
    padding: 0;  
    margin: 0;
    height: 100vh;
    overflow: hidden; //this removes an unwanted full length scroll bar
    background-color: var(--background-color);
}

*, html {
    scroll-behavior: smooth !important;
}

#root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  
    overflow-y: auto;
    font-size: 100%;
    overflow-x: hidden;
    color: var(--text-color);
    font-family: 'Trebuchet MS', 'Gill Sans', Calibri, 'Gill Sans MT', sans-serif;
}

.flex-center {
    display: flex; align-items: center; justify-content: center; 
}

main-app-header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 90;
}

main {
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: var(--background-color);
    position: relative;
    z-index: 20;
    padding-bottom: 50px;
    margin-bottom: 150px;
    font-size: calc(1rem + 1.3vw);
    contain: paint;// prevents overflow into footer
}

main-app-footer {
    position: fixed; 
    bottom: 0;
    z-index: 1;
    width: 100%;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

//404 page styles
.lost {
    padding: 2em;
    text-align: center;
}

/* mobile styles */
@media all and (max-width: 768px){   
    main {
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        padding-bottom: 30px;
        margin-bottom: 150px; //didn't change
    }

}


