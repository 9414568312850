
// ------- main video ---------------

#videos {
    width: 55vw;
    margin: 0 19vw 1em 26vw;
    color: var(--text-color);
}

.main-vid {
    border-bottom: 1px solid var(--outline);
    margin: 1em 0;
}

// .vid-link {
//     background-color: var(--vid-btn);
//     padding: 3px;
//     display: flex; align-items: left; justify-content: left; 
// }

tracker-holder {
    // z-index: 11;
    width: 260px; // this was needed also for error
    display: flex; //without this, caused an error on page with links
}

.main-href {
    text-decoration: none;
    background-color: var(--vid-btn);
    padding: 3px;
    display: flex; align-items: left; justify-content: left; 
    color: var(--text-color);
    cursor: pointer !important;
}

.thumb { 
    max-height: 150px; 
    height: 18vw;
}
.vid-info { padding-left: .5em; }
//ellipsis here not working maybe because of flex
.vid-title { 
    margin: 0; 
    font-size: 1em; 
    white-space: nowrap;  
    overflow: hidden;       
    text-overflow: ellipsis;
}
.vid-desc { margin: .3em 0 0 0; font-size: .7em; }

.done-holder {
    margin: .4em auto;
    width: 100%;
}

.done-click {
    width: 150px;
    font-size: .7em;
    color: var(--brand-color);
    padding: .5em;
    cursor: pointer;
    z-index: 24;

}

.done-btn {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 2px solid var(--outline);
    img {
        width: 10px;
        opacity: 0;
        transition: all 300ms ease-in;
    }
}

.done-txt {
    margin: 0 20px;
}

.ticked {
    img {
        width: 40px;
        opacity: 1;
    }
}




// ------------- upskill video ------------------- 


.up-vid {
    position: relative;
    margin: 0.5em 1em;
    width: auto;
    background-color: var(--upskill-bg);
    padding: 0 5px; //mystery 5 px padding on up-vid div
    border-radius: 5px;
    display: flex; align-items: stretch; justify-content:space-between; 
}

.up-info { 
    padding: 0 .5em 0 1.2em;
    width: 65%;

    display: flex;
    flex-direction: column;    
    align-self: stretch;
    justify-content: space-between;
}

// word UPSKILL
.up-type {
    margin: 0;
    font-weight: bold;
    font-size: 0.8em;
    color: var(--upskill-type);
    position: absolute;
    rotate: 90deg;
    top: 40px;
    left: -1em;
}

.up-href {
    text-decoration: none;
    color: var(--upskill-cl);
    padding: 0 !important;
    margin: 0;
}

// pic
.up-thumb { 
    border-radius: 5px; 
    padding: 2px;
    max-height: 100px; 
    width: 100%;
    cursor: pointer;
}
.up-title { 
    margin: 0; font-size: .8em;  
    padding-top: .5em;
    white-space: nowrap;  
    overflow: hidden;       
    text-overflow: ellipsis;
}
.up-desc { 
    margin: .3em 0 0 0; font-size: .6em; 
    white-space: nowrap;  
    overflow: hidden;       
    text-overflow: ellipsis;
}

.watched-click {
    margin: .4em auto;
    width: 150px;
    font-size: .7em;
    color: var(--brand-color);
    cursor: pointer;
    align-self: last baseline; 
}
// color: var(--completed-text);


// This prevents the box from losing it size on larger screens?
.done-trick {
    width: 30px;
}

.ticked {
    img {
        width: 40px;
        opacity: 1;
    }
}






@media all and (max-width: 1000px){   
    // wa-ads-desktop { display: none; }
    #videos {
        width: 60vw;
        margin: 0.5 5vw 0.5 270px;
    }

    .thumb { 
        max-height: 100px; 
        height: 15vw;
    }
}

@media all and (max-width: 768px){   

    #videos {
        width: 90vw;
        margin: 0.5 auto;
    }

}

@media all and (max-width: 550px){   

    .up-vid {
        margin: 0.3em 0;

    }
    .up-info { 
        
        padding: 0 .1em 0 0.9em;
    }

    .up-type {
        font-size: 0.7em;

        top: 30px;
        left: -1.1em;
    }

    .main-href {
        flex-direction: column;
        
    }
    .thumb {
        align-self: center;
        width: 100%;
        height: auto;
        max-height: 300px;
    }
    .vid-title { 
        margin-top: 0.5em;  
    }
    // here stack images ontop


}
