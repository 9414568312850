:root {
  --brand-color: #ef3f48;
  --brand-color-100: #fffbfb;
  --brand-color-200: #fbcccf;
  --brand-color-300: #f79da2;
  --brand-color-400: #f36e75;
  --brand-color-500: #ef3f48;
  --brand-color-600: #e8131e;
  --brand-color-700: #b90f18;
  --brand-color-800: #8a0b12;
  --brand-color-900: #5a080c;
  --brand-color-alpha-1: #ef3f481a;
  --grey-color: #9a867b;
  --grey-100: #f2f0ef;
  --grey-200: #dcd5d2;
  --grey-300: #c6bbb5;
  --grey-400: #b0a098;
  --grey-500: #9a867b;
  --grey-600: #806d62;
  --grey-700: #63544c;
  --grey-800: #463c36;
  --grey-900: #292320;
  --grey-color-100: #edebeb;
  --grey-color-200: #dbd9d9;
  --grey-color-300: #b4b4b4;
  --grey-color-400: #9f9e9e;
  --grey-color-500: gray;
  --grey-color-600: #666;
  --grey-color-700: #494949;
  --grey-color-800: #2c2c2c;
  --grey-color-900: #101010;
  --background-color: #000;
  --tracker-background: #3b3b3f;
  --text-color: var(--grey-200);
  --header-height: 70px;
  --action-color: var(--brand-color);
  --accent-color: #dc4b4c;
  --action-text: #fc676a;
  --completed-text: #779eb6;
  --footer-background: var(--accent-color);
  --icon-color: var(--grey-400);
  --title-color: var(--grey-100);
  --subtitles: #febbb4;
  --upskill-bg: #fedac3;
  --upskill-cl: var(--grey-color-800);
  --upskill-type: var(--brand-color-500);
  --outline: var(--grey-800);
  --vid-btn: var(--grey-900);
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--background-color);
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

*, html {
  scroll-behavior: smooth !important;
}

#root {
  color: var(--text-color);
  font-family: Trebuchet MS, Gill Sans, Calibri, Gill Sans MT, sans-serif;
  font-size: 100%;
  position: absolute;
  inset: 0;
  overflow: hidden auto;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

main-app-header {
  z-index: 90;
  width: 100%;
  position: fixed;
  top: 0;
}

main {
  background-color: var(--background-color);
  z-index: 20;
  contain: paint;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  margin-bottom: 150px;
  padding-bottom: 50px;
  font-size: calc(1rem + 1.3vw);
  position: relative;
}

main-app-footer {
  z-index: 1;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.lost {
  text-align: center;
  padding: 2em;
}

@media (width <= 768px) {
  main {
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-bottom: 150px;
    padding-bottom: 30px;
  }
}

.move-btn {
  scale: 1.2;
}

#about, #more-soon {
  color: var(--text-color);
  width: 60vw;
  margin: 0 20vw;
  padding-bottom: 1.5em;
  position: relative;
}

#more-soon {
  padding-top: 1.5em;
}

h1 {
  color: var(--title-color);
  text-align: center;
  margin: 0;
  padding-top: 15px;
}

#q-mark {
  float: left;
  color: var(--accent-color);
  margin: 0;
  padding-right: .1em;
  font-family: Calibri;
  font-size: 7em;
  font-weight: bold;
  line-height: .8em;
}

#p-about {
  margin: 0;
  padding-bottom: 10px;
  font-size: .7em;
}

#side-holder {
  position: relative;
}

aside {
  background-color: var(--grey-color-700);
  height: 0;
  position: sticky;
  top: 50px;
  left: 0;
}

#track-head {
  height: 0;
  margin: 0 1em 30px;
  font-size: 25px;
  position: sticky;
  top: 20px;
}

#vid-error {
  color: #ff0;
  margin: 1em;
  font-style: italic;
}

#more-soon {
  text-align: right;
  margin-top: 2em;
}

@media (width >= 1001px) {
  wa-ads-mobile {
    display: none;
  }
}

@media (width <= 1000px) {
  wa-ads-desktop {
    display: none;
  }

  section {
    width: 60vw;
    margin: 0 10vw 1em 270px;
  }
}

@media (width <= 768px) {
  section {
    width: 90vw;
    margin: auto;
  }

  aside {
    display: none;
  }

  #p-about {
    font-size: .8em;
  }

  #about, #more-soon {
    color: var(--text-color);
    width: 80vw;
    margin: 0 auto;
    padding-bottom: 1em;
    position: relative;
  }
}

@media (width <= 550px) {
  #about, #more-soon {
    width: 90vw;
  }

  h1 {
    text-align: left;
  }
}

.hero {
  width: 100%;
  height: 95vh;
  position: relative;
}

#hero-img {
  height: 60vh;
  position: absolute;
  top: 15vh;
  right: 10vw;
}

#hero-title {
  position: absolute;
  top: 7vw;
  left: 10vw;
}

#hero-title p {
  color: #fff;
  margin: 0;
  font-size: 2.5em;
}

#hero-words {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width <= 850px) {
  #hero-title {
    top: 5vw;
    left: 7vw;
  }
}

@media (width <= 768px) {
  #hero-img {
    height: 55vh;
    top: 27vh;
    right: 10vw;
  }
}

@media (width <= 500px) {
  #hero-img {
    height: 80vw;
    top: 32vh;
    right: 10vw;
  }
}

.link {
  color: var(--action-color);
}

.btn {
  background-color: var(--action-color);
  color: #fff;
  border: none;
  border-radius: 500px;
  outline: none;
  padding: .5em;
  font-family: Trebuchet MS, Gill Sans, Calibri, Gill Sans MT, sans-serif;
  text-decoration: none;
}

.start-btn {
  text-align: center;
  width: 250px;
  font-size: calc(.8rem + 1.1vw);
  font-weight: bold;
  display: inline-block;
}

#videos {
  color: var(--text-color);
  width: 55vw;
  margin: 0 19vw 1em 26vw;
}

.main-vid {
  border-bottom: 1px solid var(--outline);
  margin: 1em 0;
}

tracker-holder {
  width: 260px;
  display: flex;
}

.main-href {
  background-color: var(--vid-btn);
  align-items: left;
  color: var(--text-color);
  justify-content: left;
  padding: 3px;
  text-decoration: none;
  display: flex;
  cursor: pointer !important;
}

.thumb {
  height: 18vw;
  max-height: 150px;
}

.vid-info {
  padding-left: .5em;
}

.vid-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 1em;
  overflow: hidden;
}

.vid-desc {
  margin: .3em 0 0;
  font-size: .7em;
}

.done-holder {
  width: 100%;
  margin: .4em auto;
}

.done-click {
  color: var(--brand-color);
  cursor: pointer;
  z-index: 24;
  width: 150px;
  padding: .5em;
  font-size: .7em;
}

.done-btn {
  border: 2px solid var(--outline);
  border-radius: 5px;
  width: 30px;
  height: 30px;
}

.done-btn img {
  opacity: 0;
  width: 10px;
  transition: all .3s ease-in;
}

.done-txt {
  margin: 0 20px;
}

.up-vid {
  background-color: var(--upskill-bg);
  border-radius: 5px;
  justify-content: space-between;
  align-items: stretch;
  width: auto;
  margin: .5em 1em;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.up-info {
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  width: 65%;
  padding: 0 .5em 0 1.2em;
  display: flex;
}

.up-type {
  color: var(--upskill-type);
  margin: 0;
  font-size: .8em;
  font-weight: bold;
  position: absolute;
  top: 40px;
  left: -1em;
  rotate: 90deg;
}

.up-href {
  color: var(--upskill-cl);
  margin: 0;
  text-decoration: none;
  padding: 0 !important;
}

.up-thumb {
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  max-height: 100px;
  padding: 2px;
}

.up-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding-top: .5em;
  font-size: .8em;
  overflow: hidden;
}

.up-desc {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: .3em 0 0;
  font-size: .6em;
  overflow: hidden;
}

.watched-click {
  color: var(--brand-color);
  cursor: pointer;
  align-self: last baseline;
  width: 150px;
  margin: .4em auto;
  font-size: .7em;
}

.done-trick {
  width: 30px;
}

.ticked img {
  opacity: 1;
  width: 40px;
}

@media (width <= 1000px) {
  #videos {
    width: 60vw;
    margin: .5px 5vw .5px 270px;
  }

  .thumb {
    height: 15vw;
    max-height: 100px;
  }
}

@media (width <= 768px) {
  #videos {
    width: 90vw;
    margin: .5px auto;
  }
}

@media (width <= 550px) {
  .up-vid {
    margin: .3em 0;
  }

  .up-info {
    padding: 0 .1em 0 .9em;
  }

  .up-type {
    font-size: .7em;
    top: 30px;
    left: -1.1em;
  }

  .main-href {
    flex-direction: column;
  }

  .thumb {
    align-self: center;
    width: 100%;
    height: auto;
    max-height: 300px;
  }

  .vid-title {
    margin-top: .5em;
  }
}
/*# sourceMappingURL=index.28393582.css.map */
