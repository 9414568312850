:root {


//color sets
    $brand-color: #ef3f48;
    --brand-color: #{$brand-color};
    --brand-color-100: #{lighten($brand-color, 40%)};
    --brand-color-200: #{lighten($brand-color, 30%)};
    --brand-color-300: #{lighten($brand-color, 20%)};
    --brand-color-400: #{lighten($brand-color, 10%)};
    --brand-color-500: #{$brand-color};
    --brand-color-600: #{darken($brand-color, 10%)};
    --brand-color-700: #{darken($brand-color, 20%)};
    --brand-color-800: #{darken($brand-color, 30%)};
    --brand-color-900: #{darken($brand-color, 40%)};
    --brand-color-alpha-1: #{rgba($brand-color, 0.1)};

    $grey-color: rgb(154, 134, 123);
    --grey-color: #{$grey-color};
    --grey-100: #{lighten($grey-color, 40%)};
    --grey-200: #{lighten($grey-color, 30%)};
    --grey-300: #{lighten($grey-color, 20%)};
    --grey-400: #{lighten($grey-color, 10%)};
    --grey-500: #{$grey-color};
    --grey-600: #{darken($grey-color, 10%)};
    --grey-700: #{darken($grey-color, 20%)};
    --grey-800: #{darken($grey-color, 30%)};
    --grey-900: #{darken($grey-color, 40%)};

    //older grey set
    --grey-color-100: rgb(237, 235, 235);
    --grey-color-200: rgb(219, 217, 217);
    --grey-color-300: rgb(180, 180, 180);
    --grey-color-400: rgb(159, 158, 158);
    --grey-color-500: rgb(128, 128, 128);
    --grey-color-600: rgb(102, 102, 102);
    --grey-color-700: rgb(73, 73, 73);
    --grey-color-800: rgb(44, 44, 44);
    --grey-color-900: rgb(16, 16, 16);
    

// --------------------- THEME COLOURS -------------------------
    --background-color: #000000;
    --tracker-background: #3B3B3F;

    --text-color: #dedcdb;
    --header-height: 70px;

    --action-color: var(--brand-color);
    --accent-color: #DC4B4C;

    --action-text: #FC676A;
    --completed-text: #779EB6;
    --footer-background: var(--accent-color);
    --icon-color: var(--grey-400);

    --title-color: var(--grey-100); // test this color
    --subtitles: #FEBBB4; //not used
    --text-color: var(--grey-200);

    --upskill-bg: #FEDAC3;
    --upskill-cl: var(--grey-color-800);
    --upskill-type: var(--brand-color-500);

    --outline: var(--grey-800);
    --vid-btn: var(--grey-900);


}
