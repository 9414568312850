.move-btn {
    scale: 1.2;
}


#about, #more-soon {
    position: relative;
    width: 60vw;
    padding-bottom: 1.5em;
    margin: 0 20vw;
    color: var(--text-color);
}

#more-soon {
    padding-top: 1.5em;
}
// not working!!


h1 {
    padding-top: 15px;
    margin: 0;
    color: var(--title-color);
    text-align: center;
}

#q-mark {
    float: left;
    margin: 0;
    padding-right: 0.1em;
    font-size: 7em;
    line-height: 0.8em;
    font-family: 'Calibri';
    font-weight: bold;
    color: var(--accent-color);
}

#p-about {
    margin: 0;
    padding-bottom: 10px;
    font-size: .7em;
}

#side-holder {
    // width: 100%;
    position: relative;
}


aside {
    position: sticky;
    height: 0px;
    top: 50px;
    left: 0;
    background-color: var(--grey-color-700);
}

#track-head {
    position: sticky;
    height: 0px;
    top: 20px;
    margin: 0 1em 30px 1em ;
    font-size: 25px;
}


#vid-error {
    margin: 1em;
    color: yellow;
    font-style: italic;
}

#more-soon {
    margin-top: 2em;
    text-align: right;
}


// /* desktop styles */
@media all and (min-width: 1001px){  
    wa-ads-mobile { display: none; }
}
@media all and (max-width: 1000px){   
    wa-ads-desktop { display: none; }
    section {
        width: 60vw;
        margin: 0 10vw 1em 270px;
    }
}

/* mobile styles */
@media all and (max-width: 768px){   

    section {
        width: 90vw;
        margin: auto;
    }
    aside {
        display: none;
    }
    #p-about {
        font-size: .8em;
    }
    #about, #more-soon {
        position: relative;
        width: 80vw;
        margin: 0 auto;
        color: var(--text-color);
        padding-bottom: 1em;
    }

}

@media all and (max-width: 550px){   

    #about, #more-soon {
        width: 90vw;
    }

    h1 {
        text-align: left;
    }
}

