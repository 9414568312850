.hero {
    position: relative;
    height: 95vh;
    width: 100%;
}
#hero-img {
    height: 60vh; 
    position: absolute;
    right: 10vw;
    top: 15vh;
}
#hero-title {
    position: absolute;
    top: 7vw;
    left: 10vw;
    p {
        margin: 0;
        font-size: 2.5em;
        color: white;
    }
}
#hero-words {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}



@media all and (max-width: 850px){   
    #hero-title {
        top: 5vw;
        left: 7vw;
    }
}

/* mobile styles */
@media all and (max-width: 768px){   

    #hero-img {
        height: 55vh; 
        right: 10vw;
        top: 27vh;
    }

}


@media all and (max-width: 500px){   

    #hero-img {
        height: 80vw; 
        right: 10vw;
        top: 32vh;
    }

}
