// <a> tag class basic link
.link { 
    color: var(--action-color); 
}

// <a> & <button> 
.btn {
    background-color: var(--action-color);
    border-radius: 500px;
    padding: 0.5em;
    color: white;
    
    font-family: 'Trebuchet MS', 'Gill Sans', Calibri, 'Gill Sans MT', sans-serif;
    // remove button & a tag features 
    outline: none;
    border:none; 
    // cursor: pointer;
    text-decoration: none;
}

.start-btn {
    width: 250px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    font-size: calc(0.8rem + 1.1vw);
}

